import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Button, CircularProgress, Divider, Fade, Grid, Paper, useTheme} from '@mui/material';
import {
  AgentStatus,
  Firebase,
  GroupRole,
  useAgent,
  useCallable,
  useNotification,
} from '@ozark/common';
import {Loading} from '@ozark/common/components';
import {Fragment, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useStore} from '../../store/helpers';
import {AgentForm, AgentFormModel, AgentSchema} from './AgentForm';

export const AgentEdit = ({
  agentId,
  isAuthUserAdmin,
}: {
  agentId: string;
  isAuthUserAdmin: boolean;
}) => {
  const {authProfile} = useStore();
  const {
    document: {data: agent},
    set,
  } = useAgent(agentId);
  const {isUniqueAgentCode} = useCallable();

  const [loading, setLoading] = useState(false);
  const showNotification = useNotification();
  const theme = useTheme();

  const hookForm = useForm({
    resolver: yupResolver(AgentSchema),
    defaultValues: {
      role: GroupRole.member,
    } as any,
    shouldUnregister: true,
  });

  const {formState, handleSubmit} = hookForm;

  const {isDirty} = formState;

  const onSuccess = async (data: AgentFormModel) => {
    try {
      const {
        firstName,
        lastName,
        role,
        email,
        phone,
        dateOfBirth,
        office,
        adminContact,
        timeZoneId,
        agentStatus,
        agentCode,
        subAgentCode,
        masterUid,
        permissions,
        routingNumber,
        accountNumber,
        splitAdditionalServiceFee,
        payTo,
      } = data;

      const agentCodesWereChanged =
        agent!.agentCode !== agentCode || agent!.subAgentCode !== subAgentCode;
      if (agentCodesWereChanged) {
        const isUniqueAgentCodeResult = await isUniqueAgentCode({
          agentId: agentId,
          agentCode: agentCode,
          subAgentCode: subAgentCode,
        });
        if (isUniqueAgentCodeResult.status === 'error') {
          showNotification('error', 'Failed to Save Changes.');
          return;
        }
        if (!isUniqueAgentCodeResult.isUniqueAgentCode) {
          if (subAgentCode) {
            showNotification(
              'error',
              `Sub Agent Code ${subAgentCode} already exists for master agent. You can reload the page and try one more time.`
            );
          } else {
            showNotification(
              'error',
              `Agent Code ${agentCode} already exists. You can reload the page and try one more time.`
            );
          }
          return;
        }
      }

      const isActive = agentStatus === AgentStatus.active ? true : false;
      const isDisabled = agentStatus === AgentStatus.disabled;

      setLoading(true);
      const masterUidWasChanged = agent?.masterUid !== masterUid;

      let newAgentValues: any = {
        firstName,
        lastName,
        dateOfBirth,
        role,
        email,
        phone,
        office,
        adminContact,
        timeZoneId,
        isActive,
        isDisabled,
        masterUid: masterUid || (Firebase.FieldValue.delete() as any),
        masterUidSetBy: masterUidWasChanged ? authProfile.data!.id : agent?.masterUidSetBy,
        agentCode,
        subAgentCode: masterUid ? subAgentCode : (Firebase.FieldValue.delete() as any),
        permissions: {
          ...(agent?.permissions || {}),
          ...permissions,
        },
        routingNumber,
        accountNumber,
        splitAdditionalServiceFee,
        payTo: payTo ?? agent!.payTo,
      };

      if (!isAuthUserAdmin) {
        //members can update only some fields
        newAgentValues = {
          firstName,
          lastName,
          dateOfBirth,
          payTo: payTo ?? agent!.payTo,
        };
      }

      await set(newAgentValues);

      showNotification('success', 'Agent successfully updated.');
    } catch (err) {
      showNotification('error', 'Failed to Save Changes.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onError = async (_data: any) => {
    showNotification('error', 'Failed to Save Changes.');
    console.error(_data);
  };

  if (!agent) return <Loading />;

  return (
    <Grid item xs={12} md={7} lg={8} xl={10}>
      <Paper sx={{marginTop: 0, padding: theme.spacing(1, 2, 2), position: 'relative'}}>
        <Grid container spacing={2}>
          <AgentForm
            hookForm={hookForm}
            agent={agent}
            isAuthUserAdmin={isAuthUserAdmin}
            editMode={true}
          />
          <Fade in={isDirty}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Fade>
          <Fade in={isDirty} unmountOnExit={false}>
            <Grid item xs={12}>
              <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{width: '150px'}}
                  disabled={loading}
                  onClick={handleSubmit(onSuccess, onError)}
                >
                  {loading ? <CircularProgress size={24} /> : <Fragment>Save Changes</Fragment>}
                </Button>
              </Box>
            </Grid>
          </Fade>
        </Grid>
      </Paper>
    </Grid>
  );
};
