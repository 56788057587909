import {AuthUserClaims, GroupRole, UserRoles} from '@ozark/common';
import {
  AgentStatistics,
  AGENT_STATISTICS_ROUTES,
  Authorizations,
  AuthorizationSummaryPage,
  Batches,
  ChargebackDetails,
  ChargebacksSummary,
  ChargebacksSummaryByMid,
  DashboardPage,
  DepositsCpyDays,
  MccsBySalesPage,
  MfaAuthWizard,
  MidsByChargebacksPage,
  MidsByProcessingPage,
  NotificationsPage,
  Reserves,
  StatementsPage,
  Tickets,
  Transactions,
  VolumeSummaryPage,
} from '@ozark/common/components';
import ApplicationAnalyticsDetails from '@ozark/common/components/Applications/common/ApplicationAnalyticsDetails';
import {NOTIFICATIONS} from '@ozark/common/components/NotificationsPage/routes';
import {TICKETS_VIEW, TICKET_EDIT} from '@ozark/common/constants/routes';
import {useMemo} from 'react';
import ActivationsPage from '../components/ActivationsPage';
import Agent from '../components/Agents/Agent';
import Agents from '../components/Agents/index';
import RelationshipManagement from '../components/AgentSupport';
import {
  AgentMidAssociationPage,
  PortfolioStatisticsPage,
  TransactionsSummary,
} from '../components/Analytics';
import ApplicationPage from '../components/Application';
import Applications from '../components/Applications';
import {ApplicationsStatusesPage} from '../components/ApplicationsStatusesPage';
import AppUrl from '../components/AppUrl';
import BlankPage from '../components/BlankPage';
import Boarded from '../components/Boarded';
import {DocumentsUpload} from '../components/DocumentsUpload';
import {ForgotPassword} from '../components/ForgotPassword';
import IncompleteApplicationsPage from '../components/IncompleteApplications';
import {RestrictedAccessLayout} from '../components/Layout';
import MainLayout from '../components/Layout/MainLayout';
import Login from '../components/Login';
import MerchantRegistration from '../components/MerchantRegistration';
import Merchants from '../components/Merchants';
import MerchantDetails from '../components/Merchants/MerchantDetails';
import MerchantsPortfolio from '../components/MerchantsPortfolio';
import MerchantsPortfolioDetails from '../components/MerchantsPortfolio/Details';
import PricingPage from '../components/PricingPage';
import Profile from '../components/Profile';
import {ResetPassword} from '../components/ResetPassword';
import ResidualsAgent from '../components/Residuals/Agent';
import ResidualsGroup from '../components/Residuals/Group';
import ResidualsOverview from '../components/Residuals/Overview';
import Resources from '../components/Resources';
import Underwriting from '../components/Underwriting';
import * as ROUTES from '../constants/routes';
import {useStore} from '../store/helpers';
import {PortalLayoutRoutes} from '../types/Routes';
import {hasResidualsPermissions} from '../utils/hasResidualsPermissions';
import {isMerchantManagementAllowed} from '../utils/isMerchantManagementAllowed';

const PortalRoles = [UserRoles.agent, UserRoles.merchant];

// TODO: Rework this approach to have the same approach as we have in ERP(without hook, just simple array)
export const useComponentRoutesByRole = (): PortalLayoutRoutes => {
  const {authProfile, claims, group} = useStore();

  const routes = useMemo(() => {
    const allowAnonymous = () => true;

    const allowPermissions = (
      permittedRoles: UserRoles[],
      condition?: (claims: AuthUserClaims) => boolean
    ) => {
      if (!claims?.role) return false;
      return (
        permittedRoles.includes(claims.role as UserRoles) && (condition ? condition(claims) : true)
      );
    };

    return [
      {
        layout: null,
        routes: [
          {
            path: ROUTES.LOGIN,
            component: Login,
            isProtected: false,
          },
          {
            path: ROUTES.MERCHANT_REGISTRATION,
            component: MerchantRegistration,
            isProtected: false,
          },
          {
            path: ROUTES.RESET_PASSWORD,
            component: ResetPassword,
            isProtected: false,
            condition: allowAnonymous,
          },
          {
            path: ROUTES.FORGOT_PASSWORD,
            component: ForgotPassword,
            isProtected: false,
            condition: allowAnonymous,
          },
          {
            path: ROUTES.DOCUMENTS_UPLOAD,
            component: DocumentsUpload,
            isProtected: false,
            condition: allowAnonymous,
          },
          {
            path: ROUTES.MFA_AUTH,
            component: MfaAuthWizard,
            isProtected: false,
          },
        ],
      },
      {
        layout: RestrictedAccessLayout,
        isProtected: true,
        routes: [
          {
            path: ROUTES.APPLICATIONS_STATUSES,
            component: ApplicationsStatusesPage,
            condition: () => allowPermissions([UserRoles.merchant]),
          },
        ],
      },
      {
        layout: MainLayout,
        isProtected: true,
        routes: [
          {
            path: ROUTES.DASHBOARD,
            component: DashboardPage,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.APPLICATION,
            component: ApplicationPage,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.APPLICATIONS_ALL,
            component: Applications,
            condition: () => allowPermissions([UserRoles.agent, UserRoles.merchant]),
          },
          {
            path: ROUTES.APPLICATIONS_BOARDED,
            component: Boarded,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.APPLICATIONS_CONDITIONALLY_APPROVED,
            component: BlankPage,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.APPLICATIONS_INCOMPLETE,
            component: IncompleteApplicationsPage,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.APPLICATIONS_AS,
            component: RelationshipManagement,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.APPLICATIONS_UNDERWRITING,
            component: Underwriting,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.APPLICATIONS_START_NEW_APP,
            component: BlankPage,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.APP_URL,
            component: AppUrl,
            condition: () => {
              return (
                allowPermissions([UserRoles.agent]) &&
                !!group.data?.applicationSettings?.enableOnlineApplicationLink
              );
            },
          },
          {
            path: ROUTES.APPLICATIONS_ANALYTICS_DETAILS,
            component: ApplicationAnalyticsDetails,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.ONLINE_PRICING,
            component: PricingPage,
            condition: () =>
              allowPermissions(
                [UserRoles.agent],
                claims => claims.groupRole === GroupRole.administrator
              ),
          },
          {
            path: ROUTES.MERCHANTS_PORTFOLIO,
            component: MerchantsPortfolio,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: [ROUTES.MERCHANT_PORTFOLIO_DETAILS, ROUTES.MERCHANT_PORTFOLIO_DETAILS_TAB],
            component: MerchantsPortfolioDetails,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.MERCHANTS,
            component: Merchants,
            condition: () =>
              allowPermissions([UserRoles.agent, UserRoles.merchant], claims =>
                isMerchantManagementAllowed(claims, authProfile?.data)
              ),
          },
          {
            path: [ROUTES.MERCHANT_DETAILS],
            component: MerchantDetails,
            condition: () =>
              allowPermissions([UserRoles.agent, UserRoles.merchant], claims =>
                isMerchantManagementAllowed(claims, authProfile?.data)
              ),
          },
          {
            path: ROUTES.AUTHORIZATIONS,
            component: () => <Authorizations />,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.TRANSACTIONS,
            component: () => <Transactions />,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.BATCHES,
            component: () => <Batches transactionRouteUrl={ROUTES.TRANSACTIONS} />,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.DEPOSITS,
            component: DepositsCpyDays,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.STATEMENTS,
            component: StatementsPage,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.ACTIVATIONS,
            component: ActivationsPage,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.CHARGEBACKS,
            component: () => (
              <ChargebacksSummaryByMid chargebackDetailsUrl={ROUTES.CHARGEBACKS_DETAILS} />
            ),
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.CHARGEBACKS_SUMMARY,
            component: () => (
              <ChargebacksSummary
                routeChargebacks={ROUTES.CHARGEBACKS}
                chargbackDetailsUrl={ROUTES.CHARGEBACKS_DETAILS}
              />
            ),
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.PORTFOLIO_RESERVES,
            component: () => <Reserves />,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.PORTFOLIO_ACH_REJECTS,
            component: BlankPage,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.PORTFOLIO_TIN,
            component: BlankPage,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.PORTFOLIO_PCI,
            component: BlankPage,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES._1099K,
            component: BlankPage,
            condition: () => allowPermissions([UserRoles.merchant]),
          },
          {
            path: AGENT_STATISTICS_ROUTES,
            component: AgentStatistics,
            isExact: false,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.ANALYTICS_PORTFOLIO,
            component: PortfolioStatisticsPage,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.ANALYTICS_VOLUME_DETAIL,
            component: VolumeSummaryPage,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.ANALYTICS_AUTHORIZATION_DETAIL,
            component: AuthorizationSummaryPage,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.ANALYTICS_AGENT_MID_ASSIGNMENT,
            component: AgentMidAssociationPage,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.ANALYTICS_YEARLY_SUMMARY,
            component: TransactionsSummary,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.ANALYTICS_MCC_BY_SALES,
            component: MccsBySalesPage,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.ANALYTICS_MIDS_BY_PROCESSING,
            component: MidsByProcessingPage,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: [
              ROUTES.ANALYTICS_MIDS_BY_CHARGEBACKS,
              ROUTES.ANALYTICS_MIDS_BY_CHARGEBACKS_DETAILS,
            ],
            component: MidsByChargebacksPage,
            isExact: false,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.RESIDUALS_OVERVIEW,
            component: ResidualsOverview,
            condition: () =>
              hasResidualsPermissions(authProfile?.data) &&
              allowPermissions([UserRoles.agent]) &&
              (claims?.isGroupOwner || claims?.groupRole === GroupRole.administrator),
          },
          {
            path: ROUTES.RESIDUALS_GROUP,
            component: ResidualsGroup,
            condition: () =>
              allowPermissions([UserRoles.agent]) &&
              hasResidualsPermissions(authProfile?.data) &&
              claims?.groupRole === GroupRole.administrator,
          },
          {
            path: ROUTES.RESIDUALS_AGENT,
            component: ResidualsAgent,
            condition: () =>
              allowPermissions([UserRoles.agent]) && hasResidualsPermissions(authProfile?.data),
          },
          {
            path: [ROUTES.TICKETS, TICKET_EDIT, TICKETS_VIEW],
            component: Tickets,
            isExact: false,
            condition: () => allowPermissions([UserRoles.agent, UserRoles.merchant]),
          },
          {
            path: ROUTES.RESOURCES,
            component: Resources,
            condition: () => allowPermissions([UserRoles.agent, UserRoles.merchant]),
          },
          {
            path: ROUTES.AGENTS,
            component: Agents,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.AGENT,
            component: Agent,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.EMAIL_LOG,
            component: BlankPage,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.REPORTS,
            component: BlankPage,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.REPORTS_ANALYTICS,
            component: BlankPage,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.STATEMENTS,
            component: BlankPage,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.TRANSACTIONS_PENDING,
            component: BlankPage,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.TRANSACTIONS_SEARCH,
            component: BlankPage,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.PROFILE,
            component: Profile,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.ACCOUNT,
            component: BlankPage,
            condition: () => allowPermissions([UserRoles.merchant]),
          },
          {
            path: ROUTES.ACCOUNT_UPDATES,
            component: BlankPage,
            condition: () => allowPermissions([UserRoles.merchant]),
          },
          {
            path: ROUTES.PCI,
            component: BlankPage,
            condition: () => allowPermissions(PortalRoles),
          },
          {
            path: ROUTES.REFERRAL,
            component: BlankPage,
            condition: () => allowPermissions([UserRoles.merchant]),
          },
          {
            path: ROUTES.CHARGEBACKS_DETAILS,
            component: () => (
              <ChargebackDetails
                userId={authProfile?.data?.id}
                userName={`${authProfile?.data?.firstName} ${authProfile?.data?.lastName}`}
              />
            ),
            condition: () => allowPermissions([UserRoles.agent, UserRoles.merchant]),
          },
          {
            path: ROUTES.ONLINE,
            component: BlankPage,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.ONLINE_NEW_APP,
            component: BlankPage,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.PORTFOLIO,
            component: BlankPage,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: ROUTES.PORTFOLIO_NON_PROCESSING,
            component: BlankPage,
            condition: () => allowPermissions([UserRoles.agent]),
          },
          {
            path: NOTIFICATIONS,
            component: NotificationsPage,
            condition: () => allowPermissions(PortalRoles),
          },
        ],
      },
    ];
  }, [authProfile?.data, claims, group.data?.applicationSettings?.enableOnlineApplicationLink]);

  return routes;
};
